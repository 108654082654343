import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import LoadingDisplayRow from "@/portals/shared/cell/loading/LoadingDisplayRow";
// import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";

// import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
// import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class ListViewUtils extends RcTableViewUtils{

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.CU = new ContentUtils();
    this.SU = new StringUtils();
  }

  start() {
    super.start();
    if (this.model().isStatePageLoading()) {
      return;
    }
    if (this.model().isStateContentLoading()) {
      return;
    }

    if (!this.model().isStateContentLoaded()) {
      return;
    }

    this.renderHeader();
    this.renderBody();
    this.renderFooter();
  } 


  renderHeader() {
    // this.add(new TitleDisplayRow().withTitle("Billing"));
    //
  }

  renderBody() {
    
    this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
      .withChild(new StringDisplayRow().withWidth("3"))
      .withChild(new BoxDisplayRow().withWidth("9").withBorder(false))
      .withChild(new StringDisplayRow().withWidth("3"))
    ));
    

    var buttonBoxRow = new RowDisplayRow()
                            .withChild(new SpacerDisplayRow().withWidth(11));

    if (this.model().isAdmin()) {
      buttonBoxRow
        .withChild(new ButtonDisplayRow()
          .withName("Remove")
          .withField("RemoveCcButton")
          .withKey("RemoveCcButton")
          .withWidth(2).withId(this.model().settings().card().id()))
    } else {
      buttonBoxRow
        .withChild(new SpacerDisplayRow().withWidth(2));
    }
    
    buttonBoxRow
          .withChild(new ButtonDisplayRow()
                          .withName("Update")
                          .withField("UpdateCcButton")
                          .withKey("UpdateCcButton")
                          .withWidth(2).withId(this.model().settings().card().id()));
    var buttonBox = new BoxDisplayRow().withChild(buttonBoxRow);

    var paymentBox = new BoxDisplayRow()
      .withChild(new RowDisplayRow()
        .withChild(new BoxDisplayRow().withWidth("15").withBorder(true)
            .withChild(new TitleDisplayRow().withTitle("Payment"))
            .withChild(new BoxDisplayRow().withBorder(true)
              .withChild(new StringDisplayRow().withValue("Credit Cards will be charged between the 15th and 30th of the month"))
              .withChild(new StringDisplayRow().withValue("We can not accept American Express Cards"))
            )
            .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().card().find().name()))
            .withChild(new LabelValueDisplayRow().withLabel("Number").withValue(this.model().settings().card().find().number()))
            .withChild(new LabelValueDisplayRow().withLabel("Expiry").withValue(this.model().settings().card().find().expiry()))
            .withChild(new LabelValueDisplayRow().withLabel("Interval").withValue(this.model().settings().company().find().billingPeriod()))
            .withChild(buttonBox)
          )
      );


    var detailsBox = new BoxDisplayRow().withChild(new RowDisplayRow()
      .withChild(new BoxDisplayRow().withWidth("15").withBorder(true)
        .withChild(new TitleDisplayRow().withTitle("Invoice Details"))
        .withChild(new StringDisplayRow().withValue("This information will appear on your invoice"))
        .withChild(new BoxDisplayRow().withBorder(true)
            .withChild(new TitleDisplayRow().withTitle("Billing Company").withSubtitle(true))
            .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().companyName()))
            )

        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Billing Contact").withSubtitle(true))
          .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().contactName()))
          .withChild(new LabelValueDisplayRow().withLabel("Email").withValue(this.model().settings().contactEmail()))
          .withChild(new LabelValueDisplayRow().withLabel("Phone").withValue(this.model().settings().contactPhone()))
          .withChild(new LabelValueDisplayRow().withLabel("Address").withValue(this.model().settings().address().find().location()))
          )

        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Invoice Notification Emails and Purchase Order #").withSubtitle(true))
          .withChild(new BoxDisplayRow().withBorder(true)
            .withChild(new StringDisplayRow().withValue("Invoices sent via email the first week of the month"))
          )
          .withChild(new LabelValueDisplayRow().withLabel("Email To").withValue(this.model().settings().emailTo()))
          .withChild(new LabelValueDisplayRow().withLabel("PO").withValue(this.model().settings().purchaseOrder()))
        )
        .withChild(new BoxDisplayRow().withChild(new RowDisplayRow()
            .withChild(new SpacerDisplayRow().withWidth(13))
            .withChild(new ButtonDisplayRow().withName("Update").withField("UpdateButton").withKey("UpdateButton")
                                             .withWidth(2).withId(this.model().settings().id()))
          )
        ))
      );

    var planBox = this.addPlanInformation();

    var leftKids = [];
    leftKids.push(paymentBox);
    leftKids.push(detailsBox);
    leftKids.push(planBox);

    var rightKids = [];
    if (this.model().invoices().length > 0) {
      rightKids.push(new TitleDisplayRow().withTitle("Invoices"))
      rightKids.push(this.renderRightHeader());
      rightKids.push(this.renderRightBody());
      rightKids.push(this.renderRightFooter());
    } else {
      rightKids.push(new TitleDisplayRow().withTitle("Invoices"))
      rightKids.push(this.renderRightHeader());
      rightKids.push(new LoadingDisplayRow().withMessage("Loading invoices"))
      rightKids.push(this.renderRightFooter());
    }

    var leftRightBox = new RowDisplayRow()
                            .withChild(new BoxDisplayRow().withWidth("6").withChildren(leftKids))
                            .withChild(new BoxDisplayRow().withWidth("9").withBorder(false)
                              .withChild(new BoxDisplayRow()
                                .withChild(new BoxDisplayRow()
                                  .withChild(new BoxDisplayRow().withBorder(true)
                                    .withChildren(rightKids)
                                  )
                                )
                              )
                            );
    this.add(leftRightBox);
  }

  addPlanInformation() {
    var rows = [];
    if (this.model().hasFeatureVehicles()) {
      rows.push(new LabelValueDisplayRow()
                    .withLabel("Vehicle Storage Fee")
                    .withValue("$" + this.model().priceVehicles() + " per vehicle or trailer per month"))
    }

    if (this.model().hasFeatureInspections()) {
      rows.push(new LabelValueDisplayRow()
                    .withLabel("Vehicle Inspections")
                    .withValue("$" + this.model().priceInspections() + " per new Vehicle Inspection"))
    }

    if (this.model().hasFeatureTimesheets()) {
      rows.push(new LabelValueDisplayRow()
                    .withLabel("Hours of Service")
                    .withValue("$" + this.model().priceTimesheets() + " per On Duty Day (No charge for days recorded as Off Duty all day)"))
    }

    if (this.model().hasFeatureEquipment()) {
      rows.push(new LabelValueDisplayRow()
                    .withLabel("Equipment Storage Fee")
                    .withValue("$" + this.model().priceEquipment() + " per piece of equipment per month"))
    }

    if (this.model().hasFeatureEquipmentInspections()) {
      rows.push(new LabelValueDisplayRow()
                    .withLabel("Equipment Inspections")
                    .withValue("$" + this.model().priceEquipmentInspections() + " per new Equipment Inspection"))
    }

    var planBox = new BoxDisplayRow().withChild(new RowDisplayRow()
      .withChild(new BoxDisplayRow().withWidth("15").withBorder(false)
        .withChild(new TitleDisplayRow().withTitle("Plan Information"))
        .withChild(new BoxDisplayRow().withBorder(true)
          .withChildren(rows)
        ))
      .withChild(new StringDisplayRow().withWidth("8")));

    return planBox;
  }
  
  renderFooter() {
    //
  }

  renderRight() {
  }

  renderRightBody() {
    var box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("3").withTitle("Invoice #"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("3").withTitle("Issued On"))
        .withChild(new TitleDisplayRow().withWidth("3").withTitle("Total"))
        .withChild(new TitleDisplayRow().withWidth("2").withTitle("Due"))
        .withChild(new TitleDisplayRow().withWidth("2").withTitle("Invoice"))
        .withChild(new TitleDisplayRow().withWidth("2").withTitle("Paid"))
      );

    var even = true;
    var list = this.model().invoices();
    for (var index = 0; index < list.length; index++) {
      var invoice = list[index];
      if (invoice) {
        //
      }
      var dueDate = invoice.dueDate();
      var now = this.domain().time().now();

      var paidValue = "notpaid";
      if (invoice.paid()) {
        paidValue = "paid";
      } else {
        if (now.time() > dueDate.time()) {
          paidValue = "pastdue"
        } else if (dueDate.minus(1).time() < now.time()) {
          paidValue = "notpaid1day";
        } else if (dueDate.minue(5).time() < now.time()) {
          paidValue = "notpaid1week";
        } else {
          paidValue = "notpaid";
        }
      }
      var statusRow =new StatusDisplayRow().withWidth("3").withValue(paidValue);
      if (invoice.paid()) {
        statusRow.withHint(invoice.paidDate().displayAs_YYYY_MM_DD());
      }
      var invoiceRow = new RowDisplayRow()
      invoiceRow
        .withChild(new StringDisplayRow().withWidth("3").withValue(invoice.number()))
        .withChild(new StringDisplayRow().withWidth("3").withValue(invoice.createdDateDisplay()))
        .withChild(new StringDisplayRow().withWidth("2").withValue(invoice.totalWithTaxes().toFixed(2)))
        .withChild(new StringDisplayRow().withWidth("3").withValue(invoice.dueDateDisplay()))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("PDF").withField("Pdf").withId(invoice.id()))
        .withChild(statusRow)
        ;
      box.withChild(new BoxDisplayRow().withEven(even).withChild(invoiceRow));
      even = !even;
    }
  
    return box;
  }

  renderRightHeader() {
    var query = this.model().panel().$route.query;
    var totalItems = this.model().total();
    var headerBox = new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Filters"))
          .withChild(new SpacerDisplayRow().withWidth("11"))
          .withChild(new ButtonDisplayRow().withWidth("3").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(
            new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
          )
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("7").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringListUpdateRow().withWidth("5")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("4")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      );
    
    return headerBox;
  }

  renderRightFooter() {
    var query = this.model().panel().$route.query;
    var pages = this.model().pages();
    var totalItems = this.model().total();
    var footerBox = 
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("6").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("3"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("4")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        );
    return footerBox;
  }
}