import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";
import UpdateViewUtils from "./ListViewUtils";
import ContentUtils from '@/utils/ContentUtils.js';
import InvoiceListFilter from "@/domain/model/invoice/InvoiceListFilter.js";
import InvoiceMap from "@/domain/model/invoice/InvoiceMap.js";
// import InvoiceUtils from "@/domain/model/invoice/InvoiceUtils.js";

export default class ListViewModel extends RcTableViewModel {
    
  constructor(panel) {
    super(panel);
    this._total = 0;
    this._list = [];
    this._error = null;
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._company = this.domain().companys().findById(this._companyId);
    this._settings = this._company.invoiceSettings().find().copy();
    this._pages = [];
  }

  start() {
    super.start();
  }

  total() {
    return this._total;
  }

  pages() {
    return this._pages;
  }
  
  isAdmin() {
    return this.panel().isAdmin;
  }

  withValue(valueData) {
    super.withValue(valueData);
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
  }

  invoices() {
    return this._list;
  }

  buildRowData(tableData) {
    var utils = new UpdateViewUtils(this, tableData);
    utils.start();
    return this;
  }

  error() {
    return this._error;
  }

  settings() {
    return this._settings;
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }
  
  loadItems() {
    this._company = this.domain().companys().findById(this._companyId);
    this._settings = this._company.invoiceSettings().find().copy();

    this
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    
    this.panel().complete();
    this.loadByFilter();
    return this;
  }

  priceTimesheets() {
    if (this.hasFeatureTimesheets) {
      var company = this.domain().session().company().find();
      var assets = company.assets().find()
      return assets.priceForTimesheets().toFixed(2);
    }
    return "$0.35";
  }

  priceVehicles() {
    if (this.hasFeatureVehicles) {
      var company = this.domain().session().company().find();
      var assets = company.assets().find()
      return assets.priceForVehicles().toFixed(2);
    }
    return "$0.50";
  }

  priceInspections() {
    if (this.hasFeatureInspections) {
      var company = this.domain().session().company().find();
      var assets = company.assets().find()
      return assets.priceForInspections().toFixed(2);
    }
    return "$0.35";
  }

  priceEquipmentInspections() {
    if (this.hasFeatureEquipmentInspections) {
      var company = this.domain().session().company().find();
      var assets = company.assets().find()
      return assets.priceForEquipmentInspections().toFixed(2);
    }
    return "$0.50";
  }

  priceEquipment() {
    if (this.hasFeatureEquipment) {
      var company = this.domain().session().company().find();
      var assets = company.assets().find()
      return assets.priceForEquipment().toFixed(2);
    }
    return "$0.50";
  }

  hasFeatureVehicles() {
    return this.domain().session().company().features().find().allowsVehicles();
  }
  
  hasFeatureInspections() {
    return this.domain().session().company().features().find().allowsInspections();
  }

  hasFeatureTimesheets() {
    return this.domain().session().company().features().find().allowsTimesheets();
  }

  hasFeatureEquipment() {
    return this.domain().session().company().features().find().allowsEquipment();
  }

  hasFeatureEquipmentInspections() {
    return this.domain().session().company().features().find().allowsEquipmentInspections();
  }

  loadByFilter() {
    var filter = new InvoiceListFilter();
    var query = this.panel().$route.query;
    filter
      .withPagination(this.pageSize(query), query["page"] ? (query["page"] - 1) : 0)
      .withCompany(this._company.id())
      .done();
    
    var context = {};
    context["model"] = this;
    var request = this.domain().events().invoice().list(filter);
    request.send(this.loadedByFilter, context);
  }
  
  loadedByFilter(notification, context) {
    var model = context["model"];

    if (notification.error()) {
      model.error = notification.error();
    } else {
      var payload = notification.payload();
      var map = payload.valuesFor(InvoiceMap.MODEL_NAME);
      var query = model.panel().$route.query;
      var invoiceMap = new InvoiceMap(this.domain, map.data);
      
      model._total = payload.count();
      model._list = invoiceMap.sort();
      model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query));

      model.withStateContentLoaded();
      model.clearAndBuildTableData();
    }
  }
}